import React from "react";
import { Link } from "gatsby";
import styles from "./Menu.module.scss";

const Menu = ({ menu }) => (
  <nav className={styles["menu"]}>
    <ul className={styles["menu__list"]}>
      {menu.map((item) => {
        if (item.path.startsWith("https://")) {
          return (
            <li className={styles["menu__list-item"]} key={item.path}>
              <a href={item.path} className={styles["menu__list-item-link"]}>
                {item.label}
              </a>
            </li>
          );
        } else {
          return (
            <li className={styles["menu__list-item"]} key={item.path}>
              <Link
                to={item.path}
                className={styles["menu__list-item-link"]}
                activeClassName={styles["menu__list-item-link--active"]}
              >
                {item.label}
              </Link>
            </li>
          );
        }
      })}
    </ul>
  </nav>
);

export default Menu;
