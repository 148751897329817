import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Helmet from 'react-helmet';
import styles from './Layout.module.scss';

const PureLayout = ({ children, title, description, site, pathname }) => (
  <div className={styles.layout}>
    <Helmet title={site.siteMetadata.title}>
      <html lang="en" />
      <title>{title}</title>
      <meta name="description" content={description} />
      {/* Bing */}
      <meta name="msvalidate.01" content="22FE2B15AADC6E3047712025BBD65688" />
      {/* Google */}
      <meta
        name="google-site-verification"
        content="Ym2vyHxNXd2yFca7KooR2Dk8uSxFHQL0XeDVJkqWIp8"
      />
      <meta content="@hydrosquall" name="twitter:creator" />
      <meta content={title} name="twitter:title" />
      <meta content={description} name="twitter:description" />
      {/* Posts only */}
      {pathname && <meta name="twitter:card" content="summary_large_image" />}
      {pathname && (
        <meta
          name="twitter:image"
          content={`${site.siteMetadata.url}${pathname}/twitter-card.jpg`}
        />
      )}
      {/* https://www.chadly.net/embracing-the-indieweb/ */}
      <link href="https://github.com/hydrosquall" rel="me" />
      <link href="https://twitter.com/hydrosquall" rel="me" />
      <link
        rel="webmention"
        href="https://webmention.io/www.serendipidata.com/webmention"
      />
      <link
        rel="pingback"
        href="https://webmention.io/www.serendipidata.com/xmlrpc"
      />
    </Helmet>
    {children}
  </div>
);

const Layout = ({
  pathname, children, title, description
}) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            url
          }
        }
      }
    `}
    render={({ site }) => (
      <PureLayout
        pathname={pathname}
        title={title}
        description={description}
        site={site}
      >
        {children}
      </PureLayout>
    )}
  />
);

export default Layout;
